<template>
  <div>
    <base-nav container-classes="container-fluid" class="navbar-top border-bottom navbar-expand bg-primary navbar-dark">
      <ul class="navbar-nav align-items-center ml-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div class="pr-3 sidenav-toggler" :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }" @click="toggleSidebar">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
          <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle avatar-image" :style="{
                'background-image': `url('${profileImage}')`,
              }">
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{ title }}</span>
                <span class="ml-2">
                  <i class="fas fa-sort-down"></i>
                </span>
              </div>
            </div>
          </a>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Configuración</h6>
            </div>
            <a @click="profile()" class="dropdown-item">
              <i class="fa fa-user-circle text-primary" aria-hidden="true" ></i>
              <span>Mi perfil</span>
            </a>
            <div class="dropdown-divider"></div>
            <a @click.prevent="logout()" to="" class="dropdown-item">
              <i class="fa fa-times text-primary" aria-hidden="true"></i>
              <span>Cerrar Sesión</span>
            </a>
          </template>
        </base-dropdown>
      </ul>
    </base-nav>
  </div>
</template>
<script>
import { BaseNav } from "@/components";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = me.name;
        this.avatar = me.profile_image;
      }
    );
    await this.$store.dispatch("profile/me");
  },
  computed: {
    /* routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }, */
    profileImage() {
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  data() {
    return {
      title: "Perfil",
      avatar: null,
    };
  },
  methods: {
    profile() {
        const path = `/user/profile`;
        if (this.$route.path !== path) this.$router.push(path);
      },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
};
</script>
