import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/users`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return axios
    .get(`${url}/users/${id}?include=roles`, options).then(response => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function add(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null
  });

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return axios
    .post(`${url}/users?include=roles`, payload, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: []
  });
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios
    .patch(`${url}/users/${user.id}?include=roles`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios.delete(`${url}/users/${id}`, options);
}

function upload(user, image) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios.post(`${url}/uploads/users/${user.id}/profile-image`, payload, options).then(response => {
    return response.data.url;
  });
}

function getComplete() {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios.get(`${url}/complete-user`, options).then(response => {
    return response.data;
  });
}

function users(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  let parametros = `${params.country}/${params.limit}/${params.page}/${params.order}`;
  if(params.search) parametros += `/${params.search}`;

  return axios.get(`${url}/users/${parametros}`, options).then(response => {
    return {
      list: response.data,
      meta: response.meta
    };
  });
}
function exportUsers(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };


  return axios.get(`${url}/GetUserReport/`, options).then(response => {    
    return {
      list: response.data,
      meta: response.meta
    };
  });
}
export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  getComplete,
  users,
  exportUsers,
};
