import service from "@/store/services/app-service/cutOffDate-service";

export const state = {
    range: {},
    date_time: {},
    list: {}
}

export const mutations = {
    SET_RANGE: (state, range) => {
        state.range = range;
    },
    SET_DATETIME: (state, datetime) => {
        state.datetime = datetime;
    },
    SET_LIST: (state, list) => {
      state.list = list;
    }
}

export const actions = {
    getRange({ commit, dispatch }, params) {
      return service.getRange(params).then(range => {
        commit("SET_RANGE", range);
      });
    },
    getDatetime({ commit, dispatch }, params) {
      return service.getDatetime(params).then(datetime => {
        commit("SET_DATETIME", datetime);
      });
    },
    list({ commit, dispatch }, params) {
      return service.list(params).then(list => {
        commit("SET_LIST", list);
      });
    }
}

const getters = {
    range: state => state.range,
    datetime: state => state.datetime,
    list: state => state.list
}

const cutOffDate = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default cutOffDate;