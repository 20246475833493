import service from '@/store/services/app-service/countries-service';
//import service2 from '../../store/services/countries-service';


const state = {
    list:{},
    country:{},
    meta:{}
}

const mutations = {
    SET_LIST:(state, list)=>{
        state.list =list;
    },
    SET_RESOURCE: (state, country)=>{
        state.country = country;
    },
    SET_META: (state, meta)=>{
        state.meta = meta;
    }
};

const actions = {
    //Aquí se hace el llamado a la api
    list({commit, dispatch},params) {
        return service.list(params).then(({ list, meta })=>{           
            commit("SET_LIST", list);
            commit("SET_META", meta);
        });
    },
};
const getters = {
    list: state => state.list,
};

const countries = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default countries;