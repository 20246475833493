<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-orange py-7 py-lg-8 pt-lg-9 pt">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-light">¡Ups!</h1>
              <p class="text-lead text-white">Página no encontrada, regresa a la aplicación y continúa explorando.</p>

              <a @click="navigateToHome()" class="font-weight-bold text-white mt-5">Regresar al inicio</a>
              
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'not-found',
  methods: {
    navigateToHome() {
      this.$router.push("/home");
    }
  }
};
</script>
<style>
  /* Escritorio grande */
  @media (min-width: 1200px) {
    .pt {
      min-height: 720px !important;
    }
  }
  
  /* Escritorio mediano o tablet horizontal */
  @media (max-width: 1199px) and (min-width: 992px) {
    .pt {
      min-height: 1350px !important;
    }
  }
  
  /* Escritorio pequeño o tablet vertical */
  @media (max-width: 991px) and (min-width: 768px) {
    .pt {
      min-height: 635px !important;
    }
  }
  /* Movil */
  @media (max-width: 787px) {
    .pt {
      min-height: 1025px !important;
    }
  }
</style>
