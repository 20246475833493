import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function reset(id) {
  const idWithoutSpaces = id.replace(/\s/g, "");
  const data = {
    identification: idWithoutSpaces
  };
  
  return axios.put(`${url}/regenerate-password`, data).then(response => {
    return response;
  });
}

export default {
  reset
};
