import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios.get(`${url}/identifications`)
    .then(response => {
      return {
        list: response.data,
        meta: response.meta
      };
    });
}

export default {
    list
};
  