var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.roles.includes(1))?_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          icon: 'fa fa-home text-primary',
          path: '/home-admin',
        }}}):_vm._e(),(_vm.roles.includes(2))?_c('sidebar-item',{attrs:{"link":{
          name: 'Inicio',
          icon: 'fa fa-home text-primary',
          path: '/home',
        }}}):_vm._e(),(_vm.roles.includes(2))?_c('sidebar-item',{attrs:{"link":{
          name: 'Agregar Facturas',
          icon: 'fa fa-plus-circle text-primary',
          path: '/bills/add',
        }}}):_vm._e(),(_vm.roles.includes(2))?_c('sidebar-item',{attrs:{"link":{
          name: 'Historial facturas',
          icon: 'fa fa-history text-primary',
          path: '/bills/history',
        }}}):_vm._e(),(_vm.roles.includes(2))?_c('sidebar-item',{attrs:{"link":{
          name: 'Ganadores',
          icon: 'fa fa-trophy text-primary',
          path: '/bills/winners',
        }}}):_vm._e(),(_vm.roles.includes(1))?_c('sidebar-item',{attrs:{"link":{
          name: 'Participantes',
          icon: 'fas fa-users text-primary',
          path: '/admin/users',
        }}}):_vm._e(),(_vm.roles.includes(1))?_c('sidebar-item',{attrs:{"link":{
          name: 'Regenerar contraseñas',
          icon: 'fas fa-key text-primary',
          path: '/admin/reset-password',
        }}}):_vm._e(),(_vm.roles.includes(1))?_c('sidebar-item',{attrs:{"link":{
          name: 'Facturas',
          icon: 'fas fa-file-invoice text-primary',
          path: '/admin/bills',
        }}}):_vm._e(),(_vm.roles.includes(1))?_c('sidebar-item',{attrs:{"link":{
          name: 'Ganadores',
          icon: 'fa fa-trophy text-primary',
          path: '/admin/winners',
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }