import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  /* return axios.get(`${url}/me?include=roles`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  }); */
  return axios.get(`${url}/user`, options).then(response => {
    return {
      list: response.data,
      meta: response.meta
    };
  });
}

function update(profile) {
 /*  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });
  return axios
    .patch(`${url}/me?include=roles`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    }); */

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios.put(`${url}/user`, profile, options).then(response => {
    return response.data;
  });

}

export default {
  get,
  update
};
