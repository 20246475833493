import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

async function uploadFile(file) {
  const options = {
    headers: {
      "Content-Type": `application/${file.extencion}`,
    },
  };
  if (file.extencion == "pdf") {
    options.headers["Content-Type"] = `application/pdf`;
  } else {
    options.headers["Content-Type"] = `image/jpeg`;
  }
  const base64String = file.content.replace("data:", "").replace(/^.+,/, "");

  let respuesta;
  //Obtener el presigned url
  await axios
    .post(
      `https://1p6m49vf6h.execute-api.us-east-1.amazonaws.com/prod?file=${file.name}`
    )
    .then((response) => {
      respuesta = response;
    })
    .catch((error) => {
      console.log(error);
    });
  var instance = axios.create();
  //Quitar los headers de Auth para que no hagan conflicto con los de AWS
  delete instance.defaults.headers.common['Authorization'];
  const options2 = {
    headers:respuesta.data.upload_url.fields,
  };
  const base64 = file.content; // Place your base64 url here.
  return await fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const fd = new FormData();
      const file = new File([blob], "prueba.jpg");
      //Esta es la key generada con el presigned url y se va a subir a bills/key
      fd.append("key", 'bills/'+respuesta.data.upload_url.fields.key);
      fd.append("file", file);

      // Let's upload the file
      return instance
        .post(respuesta.data.upload_url.url, fd, options2)
        .then((response) => {
          response.data="https://megalabs-clubfacturas.s3.amazonaws.com/bills/"+respuesta.data.upload_url.fields.key;
          console.log(response)
          return response;
        })
        .catch((error) => {
          console.log("Error en catch" + error);
        });
    });
}

function add(bill) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return axios.post(`${url}/bill`, bill, options).then((response) => {
    return response.data;
  });
}

function getHistory(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  let promocion;
  if (params.promotion === "") {
    promocion = 0;
  } else {
    promocion = params.promotion.value;
  }
  let parametros = `${params.limit}/${params.page}/${params.order}/${promocion}`;
  if (params.search) parametros += `/${params.search}`;

  return axios
    .get(`${url}/bills-history/${parametros}`, options)
    .then((response) => {
      return response.data;
    });
}

function deleteBill(bill) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios.put(`${url}/bill-delete`, bill, options).then((response) => {
    return response.data;
  });
}

function getBillsTotal() {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios.get(`${url}/bills-total`, options).then((response) => {
    return response.data;
  });
}

function getBillsByUser(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let parametros = `${params.id}/${params.limit}/${params.page}/${params.order}`;
  if (params.search) parametros += `/${params.search}`;

  return axios.get(`${url}/bills/${parametros}`, options).then((response) => {
    return response.data;
  });
}

function exportBillsByUser(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let parametros = `${params.fecha_inicio}/${params.fecha_final}/${params.user}`;

  return axios
    .get(`${url}/export-bills-user/${parametros}`, options)
    .then((response) => {
      return {
        list: response.data,
        meta: response.meta,
      };
    });
}

function getAllBills(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let parametros = `${params.pais}/${params.limit}/${params.page}/${params.order}`;
  if (params.search) parametros += `/${params.search}`;

  return axios
    .get(`${url}/bills-all/${parametros}`, options)
    .then((response) => {
      return response.data;
    });
}

function exportAllBills(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  
  return axios.get(`${url}/GetBillsReport/${params}`, options).then((response) => {
    return {
      list: response.data,
      meta: response.meta,
    };
  });
}

function getTop10(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let parametros = `${params.range}/${params.country}`;

  return axios.get(`${url}/top/${parametros}`, options).then((response) => {
    return response.data;
  });
}

function exportTop10(params) {
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  let parametros = `${params.range}/${params.pais}`;

  return axios
    .get(`${url}/export-top/${parametros}`, options)
    .then((response) => {
      return {
        list: response.data,
        meta: response.meta,
      };
    });
}

export default {
  uploadFile,
  add,
  getHistory,
  deleteBill,
  getBillsTotal,
  getBillsByUser,
  exportBillsByUser,
  getAllBills,
  exportAllBills,
  getTop10,
  exportTop10,
};
