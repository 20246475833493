import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
    return axios.get(`${url}/chains`).then(response =>{
        return {
            list: response.data,
            meta: response.meta,
        };
    });

}

function get(id) {
    const options = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      };
     
    return axios.get(`${url}/chains/${id}`, options).then(response => {
      return {
          get: response.data,
          meta: response.meta
        };
      }); 
}

export default{
    list,
    get
};