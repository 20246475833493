import service from '@/store/services/app-service/geolocation-service';

const state = {
  get: {},  
  meta: {}
};

const mutations = {
 
  SET_GET: (state, get) => {
    state.get = get;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  
  get({ commit, dispatch }, params) {
    return service.getcountry(params).then(( get)=> { 
      commit("SET_GET", get.list.gec);    
    });
    
  },
};

const getters = {
    pais: state => state.get
};

const geolocation = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
export default geolocation;