<template>
  <div class="card">
    <div class="card-header">
      <h1>Editar perfil</h1>
    </div>
    <div class="card-body">
      <form ref="profile_form" @submit.prevent="handleProfileUpdate">
        <loading-overlay :active.sync="loading" :is-full-page="true" />
        <base-input
          label="Nombre"
          prepend-icon="fas fa-user"
          placeholder="Nombre"
          v-model="user.name"
          rules="required"
        />
        <validation-error :errors="apiValidationErrors.name" />
        <base-input
          label="Correo"
          prepend-icon="fas fa-envelope"
          placeholder="Correo"
          v-model="user.email"
          rules="required"
        />
        <validation-error :errors="apiValidationErrors.email" />
        <base-input
          label="Teléfono"
          prepend-icon="fas fa-phone"
          placeholder="Teléfono"
          v-model="user.phone"
          rules="required"
        />
        <validation-error :errors="apiValidationErrors.tel" />
        <!--
        <base-input alternative class="mb-3">
          <el-select
           :disabled="true"
            v-model="user.country_id"
            filterable
            placeholder="País"
            @change="changeCountry()"
          >
            <el-option
              v-for="option in countries"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.country_id" />
        <div class="vld-parent">
          <loading-overlay :active.sync="loading1" :is-full-page="false" />

          <base-input alternative class="mb-3">
            <el-select
              v-model="user.pharmacy_id"
              filterable
              placeholder="Farmacia"
              @change="ableAddress()"
            >
              <el-option
                disabled
                selected
                :key="'-- Seleccione una farmacia --'"
                :label="'-- Seleccione una farmacia --'"
                :value="0"
              >
              </el-option>
              <el-option
                v-for="option in pharmacies"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.pharmacy_id" />

          <base-input
            alternative
            class="mb-3"
            rules="required"
            prepend-icon="fas fa-map-marker-alt"
            placeholder="Dirección"
            name="address"
            :disabled="disabled"
            v-model="user.address"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.address" />
        </div>
        -->
        <base-input v-if="!user.role_id == 1" alternative label="Cadena" class="mb-3">
          <el-select
            v-model="user.representative_id"
            filterable
            placeholder="Cadena"
          >
            <el-option
              v-for="option in representatives"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.representative_id" />
        <div class="my-4">
          <base-button
            :disabled="validation"
            type="button"
            class="btn btn-primary"
            native-type="submit"
          >
            Guardar
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "UserEditCard",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
      loading: false,
      loading1: false,
      countries: [],
      pharmacies: [],
      disabled: false,
      representatives: [],
    };
  },

  methods: {
    
    changeCoutry(){
      this.loading1=true;
      this.disabled=true;
      this.address="";
      this.pharmacy_id=null;
      //segun el país que se seleccione, se van a ir filtrando las farmacias y los representantes,
      this.getPharmacies(this.country_id);
      this.getRepresentatives(this.country_id);//esto estaba desactivado
    },
    //si elige otro en dropdown de farmacia se habilida campo de direccion de lo contrario solo llena el campo y desabilita la edicion
    ableAddress(){
      if (this.user.pharmacy_id === 1) {
        this.disabled=false;
        this.user.address=""
        this.address="";        
      } else {
        this.disabled=true;
        /*let farmacia = this.pharmacies.find((e)=>e.id==this.user.pharmacy_id);
        
        if(farmacia) {
          this.user.address=farmacia.address;
          this.address = farmacia.address;
        }*/
      }
    },
   
    showScore(score) {
      this.scors = score;
    },
    
    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
       
    //peticiones para llenar los selects necesarios
    async getPharmacies(country_id){
      await this.$store.dispatch("pharmacies/get", country_id).then(()=>{
        this.pharmacies = this.$store.getters["pharmacies/get"];
        this.loading1 = false;
      });
    },

    async getRepresentatives(country_id){
      /*await this.$store.dispatch("representatives/get", country_id).then(()=>{
        this.representatives=this.$store.getters["representatives/get"];
      });*/
      //console.log(this)
      this.representatives = [
          { "value": 1, "label": "CADENA ACEVEDO" },
          { "value": 2, "label": "CADENA ASCAVI" },
          { "value": 3, "label": "CADENA BATRES" },
          { "value": 4, "label": "CADENA BLUEMEDICAL" },
          { "value": 5, "label": "CADENA CENTRO FARMACIA" },
          { "value": 6, "label": "CADENA CRUZ VERDE" },
          { "value": 7, "label": "CADENA FARMAGUA" },
          { "value": 8, "label": "CADENA FAYCO" },
          { "value": 9, "label": "CADENA GALAVISTA" },
          { "value": 10, "label": "CADENA GALENO" },
          { "value": 11, "label": "CADENA MEDINA" },
          { "value": 12, "label": "CADENA MI FARMACIA" },
          { "value": 13, "label": "CADENA WALMART" },
          { "value": 14, "label": "DROFARMAK S.A" },
          { "value": 15, "label": "DROGUERIA Y FARMACIA GLORIA" },
          { "value": 16, "label": "FARMACIA CONDOR" },
          { "value": 17, "label": "FARMACIA EMFARMA" },
          { "value": 18, "label": "FARMACIA F.L.V. S.A." },
          { "value": 19, "label": "FARMACIA FARMATODO" },
          { "value": 20, "label": "FARMACIA FE EN DIOS" },
          { "value": 21, "label": "FARMACIA GRUPO DE NEGOCIOS TEL S.A." },
          { "value": 22, "label": "FARMACIA KEILA" },
          { "value": 23, "label": "FARMACIA LA MODERNA HUEHUETENANGO" },
          { "value": 24, "label": "FARMACIA SAN JUAN VILLA SOL" },
          { "value": 25, "label": "FARMACIA SIMAN GT" },
          { "value": 26, "label": "FARMACIA URIZAR" },
          { "value": 27, "label": "FARMACIA Y DROGUERIA SANTA CECILIA" },
          { "value": 28, "label": "FARMACIA Y LIBRERIA PROFESIONAL" },
          { "value": 29, "label": "FARMACIA DON PEDRO" },
          { "value": 30, "label": "FARMACIA DR. IZQUIERDO" },
          { "value": 31, "label": "FARMACIA HERBEZ" },
          { "value": 32, "label": "RAPIFARMA" },
          { "value": 33, "label": "SIN CADENA" },
          { "value": 34, "label": "SUPER FARMACIA GT" },
        ]

    },
         
    async getCountries(){
      this.loading1 = true;
     
      await this.$store.dispatch("countries/list").then(() => {
        this.countries = this.$store.getters["countries/list"];
        this.user.country_id = 1;
        this.getPharmacies(this.user.country_id);
        this.ableAddress();
        this.getRepresentatives(this.user.country_id);
        
      });
    },
    
    

    handleProfileUpdate() {
      swal
        .fire({
          title: "¿Está seguro de actualizar esta información?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateUser();
          }
        });
    },
    async updateUser() {
      this.loading = true;
      try {
        await this.$store.dispatch("profile/update", this.user);

        this.resetApiValidation();
        this.$notify({
          message: "Perfil actualizado exitosamente",
        });

        await this.$store.getters["profile/me"];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Lo sentimos, por favor intente más tarde.",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    
  },
  created() {
    //this.getRepresentatives();
    this.getCountries();
    
  },
  computed: {
    validation: function () {
      /*
        this.user.country_id == "" ||
        this.user.pharmacy_id == "" ||
        this.user.address == "" ||
        this.user.representative_id == "" ||
        this.user.representative_id == null ||
        this.countries.length == 0 ||
        this.pharmacies.length == 0 ||
        this.representatives.length == 0
      */
      return (
        this.user.name == "" ||
        this.user.email == "" ||
        this.user.tel == ""
      );
    },
  },
};
</script>
