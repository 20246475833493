<template>
  <div>
    <div class="header bg-gradient-primary py-7">
      <div class="container">
        <div class="header-body mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-10 col-md-10 px-5">
              <card>
                <base-button @click="returnHome()" type="primary"><i class="fas fa-arrow-left"></i></base-button>
                <h3 class="card-title mb-3 text-center">TÉRMINOS Y CONDICIONES / Club de Facturas</h3>
                <p class="card-text mb-4 text-justify">La dinámica para ganar premios se encontrará vigente a 
                  partir de febrero hasta noviembre del 2,023, en donde se harán cortes según el periodo establecido para determinar los ganadores. 
                  Es válida para Guatemala, Honduras, Nicaragua, Costa Rica y Panamá.</p>
                <u>Dinámica de la promoción:</u>
                <p class="mt-3">El participante deberá cumplir con los siguientes requerimientos:</p>
                <ul>
                  <li>Ser mayor de edad.</li>
                  <li>Vivir en Guatemala, Honduras, Nicaragua, Costa Rica o Panamá.</li>
                  <li>Registrarse en <a href="https://www.clubdefacturas.com" target="_blank">www.clubdefacturas.com</a>.</li>
                  <li>Registrar todas las facturas que incluyan una venta de cualquier producto participante en la promoción correspondiente.</li>
                  <li>Quien registre la mayor cantidad de facturas por promoción durante el período establecido será el ganador.</li>
                </ul>
                <!--  <u>Productos Participantes:</u>
                <ul class="mt-3">
                  <li>Anuar t kit 2 tratamientos</li>
                  <li>Anuar t por 1 tratamiento</li>
                  <li>Vagil 90 gramos</li>
                  <li>Vagil 50 gramos</li>
                  <li>Vagil x 6 óvulos</li>
                  <li>Hidrivag (solo CR)</li>
                </ul> -->
                <u>Premios:</u>
                <p class="mt-3 card-text mb-4 text-justify">El organizador no cubrirá́ los gastos en que incurra el ganador para 
                  hacer efectivo su premio ni para el goce y disfrute del mismo, ni tampoco cualquier otro rubro que no esté́ expresamente
                  incluido en este reglamento.</p>
                <p class="card-text mb-4 text-justify">
                  El participante, al firmar de recibido su premio manifiesta su conformidad y exime de toda responsabilidad al organizador.
                  Una vez recibido el premio de conformidad, el organizador no responderá por cualquier daño, pérdida o menoscabo que sufra 
                  el mismo por razones que no le sean imputables al organizador.
                </p>
                <p class="card-text mb-4 text-justify">
                  El Organizador no se hace responsable por pérdidas de las tarjetas, ni de los saldos que no se utilizaron de las mismas.
                  Por lo que es importante que el ganador revise las políticas de canje de cada establecimiento, previo a hacer uso del premio. 
                </p>
                <p class="card-text mb-4 text-justify">
                  Se verificará la veracidad de las facturas previo a la entrega del premio. 
                </p>
                <p class="card-text mb-4 text-justify">
                  <u>Plazo para participar:</u> Participan las facturas de compras que se realicen entre el rango de cada período,
                  como se detalla en la siguiente dinámica de premiación:
                </p>
                <div class="table-responsive mb-3">
                  <table align="center" style="width:50%;" class="table table-bordered">
                    <thead class="thead-light text-center">
                      <tr>
                        <th>Inicio</th>
                        <th width="50%">Fin</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr>
                        <td>Febrero</td>
                        <td>Marzo</td>
                      </tr>
                      <tr>
                        <td>Abril</td>
                        <td>Mayo</td>
                      </tr> 
                      <tr>
                        <td>Junio</td>
                        <td>Julio</td>
                      </tr> 
                      <tr>
                        <td>Agosto</td>
                        <td>Septiembre</td>
                      </tr>
                      <tr>
                        <td>Octubre</td>
                        <td>Noviembre</td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
                <u>Sobre el ganador:</u>
                <p class="mt-3 card-text mb-4 text-justify">
                  Se considerará ganador a los participantes de cada país que ingresen la mayor cantidad de facturas 
                  con la venta del producto participante, por país.
                </p>
                <p class="mt-3 card-text mb-4 text-justify">
                  Para poder ser considerado dentro de la selección de ganadores, el participante debe haber ingresado como mínimo <strong>5
                  facturas</strong> de cajas completas (no blíster) de cualquier presentación del producto participante antes descrito.
                </p>
                <div class="table-responsive mb-3">
                  <table align="center" style="width:50%;" class="table table-bordered">
                    <thead class="thead-light text-center">
                      <tr>
                        <th>País</th>
                        <th>Cantidad de ganadores por bimestre</th>
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr>
                        <td>Guatemala</td>
                        <td>36</td>
                      </tr>
                      <tr>
                        <td>Honduras</td>
                        <td>12</td>
                      </tr> 
                      <tr>
                        <td>Nicaragua</td>
                        <td>16</td>
                      </tr> 
                      <tr>
                        <td>Costa Rica</td>
                        <td>12</td>
                      </tr>
                      <tr>
                        <td>Panamá</td>
                        <td>8</td>
                      </tr> 
                    </tbody>
                  </table>
                </div>
                <p class="card-text mb-4 text-justify">
                  <u>Anuncio de los ganadores:</u> El nombre de los ganadores se anunciará al público al finalizar el bimestre por medio 
                  de la página web <a href="https://www.clubdefacturas.com" target="_blank">www.clubdefacturas.com</a>, el organizador se contactará 
                  con los ganadores mediante llamada telefónica, se hará la verificación requerida y luego se coordinará la entrega del premio
                  correspondiente.                   
                </p>
                <u class="mt-3">Sobre el premio:</u>
                <ul class="mt-3">
                  <li>Las personas ganadoras serán acreedoras de 1 tarjeta de regalo canjeable en Walmart (Guatemala, Honduras, Nicaragua, Costa Rica), 
                    o 1 Vale de Vales Panamá (Panamá).</li>
                  <li>Los premios por período y por promoción son:</li>
                  <div class="table-responsive mb-3">
                    <table align="center" style="width:50%;" class="table table-bordered">
                      <thead class="thead-light text-center">
                        <tr>
                          <th>País</th>
                          <th class="col-sm-1">Cantidad</th>
                          <th class="col-sm-1">Premio</th>
                        </tr>
                      </thead>
                      <tbody align="center">
                          <tr>
                            <td style="vertical-align:middle;" rowspan="3">Guatemala</td>
                            <td >6</td>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>$100.00</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>$50.00</td>
                          </tr>

                          <tr>
                            <td style="vertical-align:middle;" rowspan="4">Honduras</td>
                            <td >2</td>
                            <td>$200.00</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>$150.00</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>$75.00</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>$50.00</td>
                          </tr>

                          <tr>
                            <td style="vertical-align:middle;" rowspan="3">Nicaragua</td>
                            <td >4</td>
                            <td>$130.00</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$80.00</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>$50.00</td>
                          </tr>

                          <tr>
                            <td style="vertical-align:middle;" rowspan="3">Costa Rica</td>
                            <td >4</td>
                            <td>$180.00</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$90.00</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$45.00</td>
                          </tr>

                          <tr>
                            <td style="vertical-align:middle;" rowspan="3">Panamá</td>
                            <td >2</td>
                            <td>$130.00</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>$80.00</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$50.00</td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                  <li>
                    Si un concursante resulta ganador en más de una ocasión o promoción, el reconocimiento se manejará de
                    la siguiente manera:
                  </li>
                    <ul class="mt-3">
                      <li>Tarjeta de regalo Walmart: se le recargará a su tarjeta el monto del premio nuevo.</li>
                      <li>Vales Panamá: se entregará uno nuevo.</li>
                    </ul>
                  <li>
                    Es necesaria la presencia de la persona ganadora con su documento de identificación para poder hacer 
                    entrega del premio.
                  </li>
                  <li>
                    El lugar y fecha de entrega será definido por el organizador y se comunicará a través de sus representantes 
                    asignados.
                  </li>
                  <li>El ganador deberá firmar un documento de recepción del premio.</li>  
                  <li>El ganador podrá seguir participando en las trivias activas.</li>
                </ul>
                <p>
                  El premio es indivisible, intransferible y no negociable.
                </p>
                <p>
                  Para ser acreedor de un premio, los participantes deberán cumplir con todos los requisitos establecidos 
                  en el presente reglamento. 
                </p>
                <p>
                  Los ganadores potenciales estarán sujetos a verificación por parte del organizador en cuanto a 
                  las condiciones de la promoción y sus datos personales.  
                </p>
                <p>
                  Ninguna participación deberá́ considerarse como ganadora hasta tanto no se verifique la elegibilidad del participante, 
                  la veracidad de la información brindada y el cumplimiento de los demás requisitos de este reglamento.
                </p>
                <p class="card-text mb-4 text-justify">
                  <u>Fecha límite para reclamar los premios:</u> Después de haber sido localizado y verificado el 
                  nombre del ganador, estará publicado durante una semana luego de ser notificado para reclamar el premio. 
                  Una vez vencido este plazo no tendrá́ derecho a reclamarlo ni pretender indemnización alguna.
                  Para el reclamo del premio, deberá el participante presentar el documento de identificación original y 
                  vigente con el que realizó el registro de la factura, en caso contrario, no podrá hacer retiro efectivo del premio.
                </p>
                <p class="card-text mb-4 text-justify">
                  Los premios tienen fecha de caducidad dependiendo el proveedor
                  <ul class="mt-3">
                    <li>Tarjeta de regalo Walmart: 1 año de vigencia</li>
                    <li>Vales Panamá: 1 año de vigencia</li>
                  </ul>
                </p>
                <u class="mt-3">De las fotografías y videos de los Ganadores al entregar los Premios:</u>
                <p class="mt-3 card-text mb-4 text-justify">
                  Las fotografías, videograbaciones u otras, que se recaben el día de la entrega de premios, tienen un propósito promocional. 
                </p>
                <p class="mt-3 card-text mb-4 text-justify">
                  Sin embargo, los participantes autorizan expresa e irrevocablemente desde el momento en que aceptan su participación bajo 
                  los términos y condiciones contenidos en este documento, a usarlas para propósitos de publicidad o en otros medios de 
                  comunicación, siempre que esté dentro del contexto promocional y sin que ello implique compensación monetaria o pago de 
                  suma alguna, lo cual es aceptado expresamente por los Participantes y ganadores de la Promoción.
                </p>
                <u class="mt-3">Requisitos para la entrega del premio:</u>
                <p class="card-text mb-4 text-justify">
                  Además de presentarse personalmente en el lugar y fecha acordado con el <i>Organizador</i> la persona ganadora deberá presentar su
                  <strong>Documento Personal de Identificación con el que se registró en la dinámica.</strong>
                </p>
                <p class="card-text mb-4 text-justify">
                  Nota importante: el <i>organizador</i> se reserva el derecho de excluir a los participantes que utilicen perfiles falsos, 
                  prácticas de spam u otras técnicas utilizadas para participar en dinámicas que no sean honestas.
                </p>
                <p class="card-text mb-4 text-justify">
                  <strong>*<i>El organizador</i></strong> se reserva el derecho de hacer modificaciones en los términos y 
                  condiciones sin previo aviso y/o notificación de estas.
                </p>
                <div class="text-center"> 
                  <base-button @click="returnHome()" type="primary">Regresar</base-button>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'terms-conditions',
    methods: {
      returnHome() {
        window.close();
      }
    }
  };

</script>
<style>
  /* Escritorio grande */
  @media (min-width: 1200px) {
    .pt {
      min-height: 720px !important;
    }
  }

  /* Escritorio mediano o tablet horizontal */
  @media (max-width: 1199px) and (min-width: 992px) {
    .pt {
      min-height: 1350px !important;
    }
  }

  /* Escritorio pequeño o tablet vertical */
  @media (max-width: 991px) and (min-width: 768px) {
    .pt {
      min-height: 635px !important;
    }
  }

  /* Movil */
  @media (max-width: 787px) {
    .pt {
      min-height: 1025px !important;
    }
  }


  .bg-warning{
    background: rgb(254,122,13)!important;
    background: linear-gradient(90deg, rgba(254,122,13,1) 0%, rgba(252,153,0,1) 100%) !important;
  }

</style>
