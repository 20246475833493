<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
       <!--  Inicio usuario admin o usuario normal -->
        <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Inicio',
            icon: 'fa fa-home text-primary',
            path: '/home-admin',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="roles.includes(2)"
          :link="{
            name: 'Inicio',
            icon: 'fa fa-home text-primary',
            path: '/home',
          }"
        ></sidebar-item>
      <!--  opciones del usuario normal -->
        <sidebar-item
          v-if="roles.includes(2)"
          :link="{
            name: 'Agregar Facturas',
            icon: 'fa fa-plus-circle text-primary',
            path: '/bills/add',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="roles.includes(2)"
          :link="{
            name: 'Historial facturas',
            icon: 'fa fa-history text-primary',
            path: '/bills/history',
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="roles.includes(2)"
          :link="{
            name: 'Ganadores',
            icon: 'fa fa-trophy text-primary',
            path: '/bills/winners',
          }"
        ></sidebar-item>
      <!-- opciones del usuario administrador -->
        <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Participantes',
            icon: 'fas fa-users text-primary',
            path: '/admin/users',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Regenerar contraseñas',
            icon: 'fas fa-key text-primary',
            path: '/admin/reset-password',
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Facturas',
            icon: 'fas fa-file-invoice text-primary',
            path: '/admin/bills',
          }"
        >
        </sidebar-item>
        <!--<sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Tombola',
            icon: 'fa fa-random text-primary',
            path: '/admin/tombola',
          }"
        >
        </sidebar-item>
         <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Verificación',
            icon: 'fa fa-check text-primary',
            path: '/admin/verify',
          }"
        >
        </sidebar-item> --> 
        <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Ganadores',
            icon: 'fa fa-trophy text-primary',
            path: '/admin/winners',
          }"
        >
        </sidebar-item>    
        
        <!-- <sidebar-item
          v-if="roles.includes(1)"
          :link="{
            name: 'Top 10',
            icon: 'fa fa-arrow-circle-up text-primary',
            path: '/admin/top',
          }"
        >
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 50);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import { FadeTransition } from "vue2-transitions";
import Vuex from "vuex";

export default {
  components: {
    DashboardNavbar,
    FadeTransition,
  },
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar()//, this.$store.dispatch("profile/me");
  },
  computed: {
    ...Vuex.mapState({
      
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles.push(val.role_id);
      },
      deep: true,
    },
    

  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
