import service from "@/store/services/app-service/identifications-service";

const state = {
  list: {},
  identification: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, identification) => {
    state.identification = identification;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
    list({ commit, dispatch }, params) {
      return service.list(params).then(({ list, meta }) => {
        commit("SET_LIST", list);
        commit("SET_META", meta);
      });
    },
};

const getters = {
    list: state => state.list,
};

const identifications = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
export default identifications;