import service from "@/store/services/app-service/bills-service";

export const state ={
    bill:{},
    path:"",
    total:{},
    bills: {},
    exports_by_user: {},
    all_bills: {},
    export_all_bills: {},
    top: {},
    export_top: {},
    deletedBill: {},
}


export const mutations = {
    SET_PATH:(state, path) => {
        state.path = path;
    },
    SET_RESOURCE:(state, bill) => {
        state.bill = bill;
    },
    SET_TOTAL:(state, total) => {
        state.total = total;
    },
    SET_LIST: (state, bills) => {
        state.bills = bills;
    },
    SET_EXPORTS_BY_USER: (state, exports_by_user) => {
        state.exports_by_user = exports_by_user;
    },
    SET_ALL_BILLS: (state, all_bills) => {
        state.all_bills = all_bills;
    },
    SET_EXPORTS_ALL_BILLS: (state, export_all_bills) => {
        state.export_all_bills = export_all_bills;
    },
    SET_TOP: (state, top) => {
        state.top = top;
    },
    SET_EXPORTS_TOP: (state, export_top) => {
        state.export_top = export_top;
    },
    SET_DELETEDBILL: (state, deletedBill) => {
        state.deletedBill = deletedBill;
    }
}

export const actions ={
    uploadFile({commit, dispatch}, params){
        return service.uploadFile(params).then(path =>{
            commit("SET_PATH", path);
        });
    },
    add({ commit, dispatch }, params) {
        return service.add(params).then(bill => {
          commit("SET_RESOURCE", bill);
        });
    },
    getHistory({ commit, dispatch }, params) {
        return service.getHistory(params).then(bill => {
          commit("SET_RESOURCE", bill);
        });
    },
    update({ commit, dispatch }, params) {
        return service.deleteBill(params).then(bill => {
          commit("SET_RESOURCE", bill);
        });
    },
    getTotal({ commit, dispatch }) {
        return service.getBillsTotal().then(total => {
          commit("SET_TOTAL", total);
        });
    },
    getBillsByUser({ commit, dispatch }, params) {
        return service.getBillsByUser(params).then(bills => {
          commit("SET_LIST", bills);
        });
    },
    exportBillsByUser({commit, dispatch}, params = {}) {
        return service.exportBillsByUser(params, this.$axios)
          .then(({list}) => {
            commit('SET_EXPORTS_BY_USER', list);
          });
    },
    getAllBills({ commit, dispatch }, params) {
        return service.getAllBills(params).then(all_bills => {
          commit("SET_ALL_BILLS", all_bills);
        });
    },
    exportAllBills({commit, dispatch}, params = {}) {
        return service.exportAllBills(params, this.$axios)
          .then(({list}) => {
            commit('SET_EXPORTS_ALL_BILLS', list);
          });
    },
    getTop10({ commit, dispatch }, params) {
        return service.getTop10(params).then(top => {
          commit("SET_TOP", top);
        });
    },
    exportTop10({commit, dispatch}, params = {}) {
        return service.exportTop10(params, this.$axios)
        .then(({list}) => {
          commit('SET_EXPORTS_TOP', list);
        });
    },
    deleteBill({ commit, dispatch }, params) {
        return service.deleteBill(params).then(deletedBill => {
          commit("SET_DELETEDBILL", deletedBill);
        });
    }
}

const getters = {
    path: state => state.path,
    bill: state => state.bill,
    total: state => state.total,  
    bills: state => state.bills,
    exports_by_user: state => state.exports_by_user,
    all_bills: state => state.all_bills,
    export_all_bills: state => state.export_all_bills,
    top: state => state.top,
    export_top: state => state.export_top,
    deletedBill: state => state.deletedBill
}

const bills = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

export default bills;