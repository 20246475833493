import axios from 'axios';

const url = "https://wh.automate.io/webhook/";

function sendError(params) {
    const options = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/vnd.api+json',
        }
    };

    return axios.post(`${url}60b149fa50343e3612e6a7b6`, params, options).then(response => {
        return response.data;
    })
}


export default {
    sendError,
    
  }