import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;
const url2= 'https://api.ipify.org?format=json';

function getcountry(ip) {
    
    return axios.get(`${url2}`).then(response =>{
        return axios.get(`${url}/geolocation/${response.data.ip}`).then(response =>{
            return {
                list: response.data   
            };
        });
    });

}
export default{
    getcountry,
};