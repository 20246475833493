import DashboardLayout from "@/views/Layout/app-layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";
import TermsCoditions from "@/views/GeneralViews/ConditionsTerms.vue";
// Example pages
import UserProfile from "@/views/AppViews/UserProfile/UserProfile.vue";

// Dashboard pages
const HomeUser = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/AppViews/HomeUser/Dashboard.vue");
const HomeAdmin = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/AppViews/HomeAdmin/AlternativeDashboard.vue" );
  
// Pages
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "passwordEmail" */ "@/views/Password/Email.vue");

//Bills
const AddBill = () =>
  import(/* webpackChunkName: "bills" */  "@/views/AppViews/Bills/AddBill.vue");
const History = () =>
  import(/* webpackChunkName: "history" */  "@/views/AppViews/Bills/History.vue");

//admin
const UsersList = () =>
  import( /* webpackChunkName: "userList" */ "@/views/AppViews/Reports/UsersList.vue");
const BillsList = () =>
  import( /* webpackChunkName: "BillList" */ "@/views/AppViews/Reports/BillsList.vue");
const AllBills = () =>
  import( /* webpackChunkName: "Allbills" */ "@/views/AppViews/Reports/AllBills.vue");
const ResetPwd = () =>
  import( /* webpackChunkName: "ResetPwd" */ "@/views/AppViews/ResetPwd/ResetPwd.vue");


//tombola
const Tombola = () =>
  import( /* webpackChunkName: "tombola" */ "@/views/AppViews/Tombola/Tombola.vue");
const Verify = () =>
  import( /* webpackChunkName: "verify user" */ "@/views/AppViews/Tombola/VerifyUser.vue");
const Winners = () =>
  import( /* webpackChunkName: "winner" */ "@/views/AppViews/Tombola/ViewWinners.vue");
const WinnersUsers = () =>
  import( /* webpackChunkName: "winnerUser" */ "@/views/AppViews/Tombola/ViewWinnersUser.vue");
//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import user from "@/middleware/user";


let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { middleware: guest }
    },
    {
      path: "/password/email",
      name: "PasswordEmail",
      component: PasswordEmail,
      meta: { middleware: guest }
    },  
    
  ]
};

let userProfile ={
  path: "/user",
  component: DashboardLayout,
  name:"User Profile",
  redirect:"/user/profile",
  children:[
    {
      path:"profile",
      name:"Perfil de usuario",
      components: {
        default: UserProfile
      },
      meta: {
        middleware: auth
      }
    }
  ]
}
let billsMenu = {
  path: "/bills",
  component: DashboardLayout,
  name: "",
  redirect: "/bills/add",
  children: [{
    path: "add",
    name: "Guardar factura",
    component: AddBill,
    meta: {
      middleware: user
    }
  },
  {
    path: "history",
    name: "Historial de facturas",
    component: History,
    meta: {
      middleware: user
    }
  },
  {
    path: "winners",
    name: "Ganadores de la tombola",
    component: WinnersUsers,
    meta: {
      middleware: user
    }
  }]
}
let adminMenu = {
  path: "/admin",
  component: DashboardLayout,
  name: "",
  children:[
    {
      path: "users",
      name: "Listado usuarios",
      component: UsersList,
      meta: {
        middleware: admin
      }
    },
    {
      path: "reset-password",
      name: "Regenerar contraseña",
      component: ResetPwd,
      meta: {
        middleware: admin
      }
    },
    {
      path: "bills",
      name: "Reporte de Facturas",
      component: AllBills,
      meta: {
        middleware: admin
      }
    },
    {
      path: "bills-user/:id",
      name: "Listado facturas",
      component: BillsList,
      meta: {
        middleware: admin
      }
    },
    {
      path: "tombola",
      name: "Tombola",
      component: Tombola,
      meta: {
        middleware: admin
      }
    },
    {
      path: "verify",
      name: "Verificación",
      component: Verify,
      meta: {
        middleware: admin
      }
    },
    {
      path: "winners",
      name: "Ganadores",
      component: Winners,
      meta: {
        middleware: admin
      }
    },
    /* {
      path: "top",
      name: "Top 10",
      component: Top10,
      meta: {
        middleware: admin
      }
    }, */
  ]
}
const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  userProfile,
  billsMenu,
  adminMenu,
  {
    path: "/",
    component: DashboardLayout,
    name: "",
    children: [
      {
        path: "home",
        name: "Home user",
        component: HomeUser,
        meta: {
          middleware: user
        }
      },
      {
        path: "home-admin",
        name: "Home admin",
        component: HomeAdmin,
        meta: {
          middleware: admin
        }
      },
      
    ]
  },
  authPages,
  {
    path: "/terms-conditions",
    component: TermsCoditions
  },
  {
    path: "**",
    component: NotFound
  }
];

export default routes;
