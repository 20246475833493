import service from '@/store/services/users-service';
import Jsona from 'jsona';

const jsona = new Jsona();

export const state = () => ({
  list: {},
  user: {},
  meta: {},
  url: null,
  user_complete: {},
  users: {},
  exports: {},
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, user) => {
    state.user = user;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_COMPLETE: (state, user_complete) => {
    state.user_complete = user_complete;
  },
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_EXPORTS: (state, exports) => {
    state.exports = exports;
  },
};

export const actions = {
  list({commit, dispatch}, params = {}) {
    return service.list(params, this.$axios)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params, this.$axios).then((user) => { 
      commit('SET_RESOURCE', user); 
    });
  },

  add({commit, dispatch}, params) {
    return service.add(params, this.$axios).then((user) => { 
      commit('SET_RESOURCE', user); 
    });
  },

  update({commit, dispatch}, params) {
    return service.update(params, this.$axios).then((user) => {
       commit('SET_RESOURCE', user);
    });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params, this.$axios);
  },

  upload({commit, dispatch}, {user, image}) {
    return service.upload(user, image, this.$axios)
      .then((url) => {
        commit('SET_URL', url);
      });
  },
  getComplete({ commit, dispatch }, params) {
    return service.getComplete().then(user_complete => {
      commit("SET_COMPLETE", user_complete);
    });
  },
  users({commit, dispatch}, params = {}) {
    return service.users(params, this.$axios)
      .then(({list, meta}) => {
        commit('SET_USERS', list);
        commit('SET_META', meta);
      });
  },
  exportUsers({commit, dispatch}, params = {}) {    
    return service.exportUsers(params, this.$axios).then(({list, meta}) => {
        commit('SET_EXPORTS', list);
        commit('SET_META', meta);
      });
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  user: state => state.user,
  url: state => state.url,
  user_complete: state => state.user_complete,
  users: state => state.users,
  exports: state => state.exports,
};

const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default users;
