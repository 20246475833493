import Vue from "vue";
import Vuex from "vuex";

// import alerts from "./modules/alerts-module";
//import categories from "./modules/categories-module";
//import tags from "./modules/tags-module";
//import items from "./modules/items-module";
import auth from "./modules/auth";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import reset from "./modules/reset";
import pwd from "./modules/app-modules/pwd-module";
import countries from "./modules/app-modules/countries-module";
import identifications from "./modules/app-modules/identifications-module";
import pharmacies from "./modules/app-modules/pharmacies-module";
import representatives from "./modules/app-modules/representatives-module";
import geolocation from "./modules/app-modules/geolocation-module";
import bills from "./modules/app-modules/bills-module";
import cutOffDate from "./modules/app-modules/cutOfDate-module";
import errors from "./modules/app-modules/errors-module";
import tombola from "./modules/app-modules/tombola-module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    users,
    pwd,
    roles,
    reset,
    countries,
    identifications,
    pharmacies,
    representatives,
    geolocation,
    bills,
    cutOffDate,
    errors,
    tombola,
    // alerts,
    //categories,
    //tags,
    //items,
  }
});
