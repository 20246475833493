import service from "@/store/services/app-service/errors-service";

export const state = {
    error: {}
};

export const mutations = {
    SET_RESOURCE: (state, error) => {
      state.error = error;
    }
};

export const actions = {
  sendError({ commit }, params) {
    return service.sendError(params).then(error => {
      commit("SET_RESOURCE", error);
    });
  },
  
};

const getters = {
    error: state => state.error
};

const errores = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
    
export default errores;