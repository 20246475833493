import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

function getRange() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/range`, options).then(response => {
    return response.data;
  });
}

function getDatetime() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/dateTime`, options).then(response => {
    return response.data;
  });
}

function list() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/cuttoff-date`, options).then(response => {
    return response.data;
  });
}

export default {
    getRange,
    getDatetime,
    list
}