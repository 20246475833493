import Vue from "vue";
import axios from "axios";
import App from "./App.vue";

//plugins
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueAxios from "vue-axios";
/* import VueMeta from 'vue-meta';
import IsDemo from "./plugins/isDemo" */

import "./assets/css/style.css";
//******************************************* Registro de componenetes globales **************************************************/
// router&store setup
import router from "./router";
import store from "./store";
//Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
//reCaptcha
//import { VueReCaptcha } from "vue-recaptcha-v3";
//Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading-overlay', Loading)
//Mask
import VueMask from 'v-mask';
Vue.use(VueMask);
//Countdown
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component('countdown', VueCountdown);
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
//Vue.use(VueReCaptcha, { siteKey: "6LeWz-MeAAAAAFpY123bTJcQKyfh5uzA3JL1-6D1" });

Sentry.init({
  Vue,
  dsn: "https://61180ab69d9c4578a65b87573fcc33bc@o953594.ingest.sentry.io/6347253",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["clubdefacturas.com", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

//Vue.use(IsDemo);
//Vue.use(VueMeta, { keyName: 'head' });

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   render: h => h(App),
//   router
// });
/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: h => h(App)
});

store.$app = app;
