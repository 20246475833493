import service from "@/store/services/app-service/pwd-service";

export const state = {
    reset: ""
}

export const mutations = {
    SET_RESET: (state, reset) => {
        state.reset = reset;
    }
}

export const actions = {
    reset({ commit, dispatch }, params) {
        return service.reset(params).then(msg => {
            commit("SET_RESET", msg);
        });
    }
}

const getters = {
    reset: state => state.reset
}

const pwd = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

export default pwd;