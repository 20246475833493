import service from "@/store/services/app-service/pharmacies-service";

const state = {
  list: {},
  get: {},
  pharmacy: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_GET: (state, get) => {
    state.get = get;
  },
  SET_RESOURCE: (state, pharmacy) => {
    state.pharmacy = pharmacy;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
    list({ commit, dispatch }, params) {
      return service.list(params).then(({ list, meta }) => {
       
        commit("SET_LIST", list);
        commit("SET_META", meta);
      });
    },
    get({ commit, dispatch }, params) {
      return service.get(params).then(({ get, meta }) => {        
        /* get.unshift({
          address:"",
          country_id:-1,
          id:-1,
          name:"Otros",
        });  */
        commit("SET_GET", get);
        commit("SET_META", meta);
      });
    },
};

const getters = {
    list: state => state.list,
    get: state => state.get
};

const pharmacies = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
export default pharmacies;