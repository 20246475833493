import service from "@/store/services/app-service/tombola-service";

const state = {
  list: {},
  get: {},
  saveWinners:{},
  getWinners:null,
  deleteWinner:null,
  exportData:null,
  excluidos: [],
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_GET: (state, get) => {
    state.get = get;
  },
  SET_WINNERS: (state, saveWinners) => {
    state.saveWinners = saveWinners;
  },
  SET_VIEW_WINNERS: (state, getWinners) => {
    state.getWinners = getWinners;
  },
  SET_DELETE: (state, deleteWinner) => {
    state.deleteWinner = deleteWinner;
  },
  SET_EXCLUIDOS: (state, excluidos) => {
    state.excluidos = excluidos;
  },
  SET_EXPORT_DATA: (state, exportData) => {
    state.exportData = exportData;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
    list({ commit, dispatch }, params) {
      return service.list(params).then(({ list, meta }) => {
        commit("SET_LIST", list);
        commit("SET_META", meta);
      });
    },
    get({ commit, dispatch }, params) {
      return service.get(params).then(({ get, meta }) => {
        commit("SET_GET", get);
        commit("SET_META", meta);
      });
    },
    save({commit}, params){
      return service.save(params).then((response) =>{
        commit("SET_WINNERS", response);
      });
    },
    saveWinnersVerified({commit}, params){
      return service.saveVerified(params).then((response) =>{
        commit("SET_WINNERS", response);
      });
    },


    viewWinners({ commit }, params) {
      return service.geWinners(params).then(({winners}) => {
        commit("SET_VIEW_WINNERS", winners);
      });
    },
    viewWinnersVerify({ commit }, params) {
      return service.geWinnersVerify(params).then(({winners}) => {
        commit("SET_VIEW_WINNERS", winners);
      });
    },
    
    deleteWinner({ commit }, params) {
      return service.deleteWinner(params).then(({response}) => {
        commit("SET_DELETE", response);
      });
    },
    agregar_excluido({commit}, id_excluido){    
      return commit("SET_EXCLUIDOS", [...state.excluidos, id_excluido]);     
    },
    vaciar_excluidos({commit}){    
      return commit("SET_EXCLUIDOS", []);     
    },
    exportWinners({commit}, params){    
      return service.getExportWinners(params).then(({winners}) => {
        commit("SET_EXPORT_DATA", winners);
      });  
    }
};

const getters = {
    list: state => state.list,
    get: state => state.get,
    saveWinners: state => state.saveWinners,
    getWinners: state => state.getWinners,
    deleteResponse: state => state.deleteWinner,
    excluidos: state => state.excluidos,
    exportData: state => state.exportData
};

const tombola = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
  
export default tombola;