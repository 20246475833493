import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list() {
  return axios.get(`${url}/representatives`)
    .then(response => {
      return {
        list: response.data,
        meta: response.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/representatives/${id}`, options)
    .then(response => {
      return {
        get: response.data,
        meta: response.meta
      };
    });
}

export default {
    list,
    get
};
  