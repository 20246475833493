import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
  let parametros = `${params.country}/${params.cutOffDate}/${params.winners}`;

  return axios.get(`${url}/winners/${parametros}`).then(response => {
      return {
        list: response.data,
        meta: response.meta
      };
    });
}

function get(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  let parametros = `${params.country}/${params.cutOffDate}`; 
  return axios.post(`${url}/winner/${parametros}`, params.excluidos, options).then(response => {
   
      return {
        get: response.data,
        meta: response.meta
      };
    });
}

function save(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save-winners`, params, options).then(response => {
      return {
        get: response.data
      };
  });
}
function saveVerified(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.put(`${url}/verify-winners`, params, options).then(response => {
      return {
        get: response.data
      };
  });
}


function geWinners(params) {
  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };
  
  return axios.get(`${url}/get-winners/` + params.phase + `/` + params.country, params, options).then(response => {    
      return {
        winners: response.data
      };
  });
}
function geWinnersVerify(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  let parametros = `${params.country}/${params.cutOffDate}`;  
  return axios.get(`${url}/winners-for-veriry/${parametros}`, options).then(response => {    
      return {
        winners: response.data
      };
  });
}

function getExportWinners(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  let parametros = `${params.country}/${params.cutOffDate}`;  
  return axios.get(`${url}/export-winners/${parametros}`, options).then(response => {    
      return {
        winners: response.data
      };
  });
}

function deleteWinner(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  return axios.put(`${url}/winner-delete`, params, options).then(response => { 
      return {
        response: response.data
      };
  });
}

export default {
    list,
    get,
    save,
    geWinners,
    deleteWinner,
    getExportWinners,
    geWinnersVerify,
    saveVerified
};
  