import store from "../store";

/* export default async function admin({ next, router }) {
  await store.dispatch("profile/me");
  let me = await store.getters["profile/me"];

  if (store.getters.isAuthenticated && me.roles[0].name!=='admin') {
   return router.push({ path: "/dashboard" });
  }
  return next();
}
 */


export default async function admin({ next, router }) {
  try {
    if (!store.getters.isAuthenticated) {
      return router.push({ name: "Login" });
    }
  
    await store.dispatch("profile/me");
    let me = await store.getters["profile/me"];
  
    if (me.role_id !== 1) {
      return router.push({ path: "/home" });
    }
    return next();
  } catch(error) {
    localStorage.clear();
    location.reload();
  }
}
